.manufacturing-landing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding-bottom: 100px;
}

.manufacturing-landing-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  width: 100%;
  color: #fff;
  background-color: var(--greyish-brown);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5%;
  padding-left: 2.5%;
  width: 50%;
  height: 100%;
  transition: all 500ms ease-in-out;
  cursor: pointer;
  position: relative;
}

.hero > h1,
.hero > h4 {
  width: fit-content;
  max-width: 800px;
  text-align: center;
  z-index: 1;
  padding: 0 25px 0 0;
  margin-left: auto;
  text-shadow: #333 1px 0px 1px;
}

.hero > h1 {
  font-size: 4vw;
  line-height: 1;
  letter-spacing: -1px;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 75px;
  font-weight: bolder;
}

.hero > h4 {
  font-size: 22px;
  margin: 0;
}

.active-hero {
  color: #fff;
  width: 80%;
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  pointer-events: none;
  transition: all 500ms ease-in-out;
  z-index: 1;
}

.manufacturing-landing-intro-section {
  font-size: 27px;
  width: 65%;
  max-width: 1000px;
  padding: 50px 0;
}

.manufacturing-landing-intro-section > h2 {
  color: var(--brownish-orange);
  line-height: 1;
}

.manufacturing-landing-intro-section > p {
  margin: 30px 0;
}

.manufacturing-landing-info-section {
  display: flex;
  width: 95%;
  max-width: 1400px;
  padding: 25px 0;
}

.manufacturing-landing-info-section > div {
  width: 22%;
  margin: 10px auto;
}

.manufacturing-landing-info-section h4 {
  font-size: 22px;
  line-height: 1;
  min-height: 40px;
}

.manufacturing-card-section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
  position: relative;
}

.manufacturing-card-link {
  z-index: 10;
}

.manufacturing-card-container {
  width: 80%;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.off-grey-background {
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: #e9e9eb;
  top: 100px;
  z-index: 0;
}

.manufacturing-card {
  height: 280px;
  width: 220px;
  margin: 25px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: var(--brownish-orange);
  transition: all 400ms ease-in-out;
}

.manufacturing-card:hover {
  background-color: var(--dark-orange);
}

.orange-top {
  height: 35%;
  color: #fff;
  /* background-color: var(--brownish-orange); */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .orange-top:hover {
  background-color: var(--dark-orange);
} */

.orange-top > h4 {
  line-height: 1;
  letter-spacing: -1px;
  width: 78%;
  font-size: 22px;
}

.black-bottom {
  background-color: #000;
  height: 65%;
}

.black-bottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.manufacturing-about-section {
  width: 60%;
  min-height: 340px;
  max-width: 1000px;
  padding: 50px 0;
}

.manufacturing-about-section h3 {
  font-size: 22px;
  color: black;
  font-family: openSans-Extra-Bold;
  margin: 0;
  margin-top: 50px;
  margin-bottom: 15px;
}

.manufacturing-landing-hero-hr {
  min-height: 550px;
  border-right: 1px solid var(--brownish-orange);
  border-left: none;
}

.hero-img {
  width: 75vw;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 0;
  object-fit: cover;
}

.hero-img-left {
  left: 0;
}

.hero-img-right {
  right: 0;
}

@media screen and (min-width: 1200px) {
  .hero > h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 950px) {
  .hero > h1 {
    font-size: 40px;
  }

  .off-grey-background {
    height: 600px;
  }
}

@media screen and (max-width: 900px) {
  .manufacturing-landing-info-section {
    flex-wrap: wrap;
  }

  .manufacturing-landing-info-section > div {
    min-width: 275px;
    min-height: 210px;
  }
  .hero-img {
    width: 100vw;
  }
}

@media screen and (max-width: 700px) {
  .off-grey-background {
    height: 950px;
  }
}

@media screen and (max-width: 650px) {
  .manufacturing-landing-hero-section {
    flex-direction: column;
  }

  .hero {
    width: 100%;
    height: 50%;
  }

  .manufacturing-landing-hero-hr {
    min-height: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--brownish-orange);
  }
}

@media screen and (max-width: 500px) {
  .manufacturing-landing-intro-section {
    width: 80%;
    padding-bottom: 0;
  }

  .manufacturing-landing-info-section > div {
    min-height: 175px;
  }

  .manufacturing-about-section {
    width: 80%;
    padding: 30px 0;
  }

  .manufacturing-landing-container {
    padding-bottom: 30px;
  }

  .hero > h1 {
    font-size: 34px;
  }

  .hero > h4 {
    font-size: 18px;
  }

  .manufacturing-landing-intro-section > h2 {
    font-size: 24px !important;
  }
}
