.interactable-btn {
  border: none;
  color: #fff;
  font-size: 20px;
  font-family: openSans-bold;
  background-color: transparent;
  padding: 10px 30px;
  margin: 0 10px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  transition: all 300ms ease-in-out;
}

.active-interactable-btn {
  border-bottom: 5px solid var(--brownish-orange);
  color: var(--brownish-orange);
}

.interactable-main-img {
  width: 75%;
  margin: 100px 0;
}

.interactable-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interactable-img-container > div {
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.new-interactable-hr {
  width: 90%;
  max-width: 1400px;
  border: none;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.35);
  margin: auto;
}

.interactable-plus-icon {
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: var(--brownish-orange);
  padding: 8px;
  border-radius: 100%;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interactable-plus-icon::after {
  content: "";
  border-radius: 100%;
  box-shadow: 0 2px 3px 0 var(--brownish-orange); /* Border bottom */
  box-shadow: 0 -2px 3px 0 var(--brownish-orange); /* Border top */
  box-shadow: -2px 0 3px 0 var(--brownish-orange); /* Border left */
  box-shadow: 2px 0 3px 0 var(--brownish-orange); /* Border right */
  box-shadow: 0 0 3px 2px var(--brownish-orange);
  position: absolute;
  width: 200%;
  height: 200%;
  margin: 0;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  animation: pulse2 2s ease-in-out infinite;
}

.interactable-plus-icon > img {
  width: 100%;
  height: 100%;
}

.exterior-top {
  top: 20%;
  left: 50%;
}

.exterior-middle {
  top: 50%;
  left: 50%;
}

.exterior-right {
  top: 35%;
  right: 15%;
}

.exterior-bottom {
  bottom: 25%;
  left: 30%;
}

.inner-left {
  bottom: 18%;
  left: 12%;
}

.inner-middle {
  top: 26%;
  left: 50%;
}

.inner-right {
  top: 30%;
  right: 15%;
}

.interactable-data-modal {
  position: absolute;
  background-color: #fff;
  color: #000;
  width: 75%;
  max-width: 700px;
  height: 400px;
  padding: 50px;
  text-align: start;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.interactable-data-modal > h3 {
  color: #000;
}

.interactable-modal-close-btn {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 900px) {
  #enclosure-interactable-section > div {
    padding: 0 25px;
  }
}

@media screen and (max-width: 640px) {
  .interactable-data-modal {
    width: 80%;
    height: fit-content;
  }

  .interactable-img-container > div {
    width: 95%;
    padding: 25px;
  }

  .new-interactable-hr {
    width: 95%;
  }

  .content-container > p {
    margin: 25px !important;
  }

  .enclosure-landing-container h2 {
    font-size: 26px !important;
  }

  .enclosure-landing-container p {
    font-size: 16px !important;
  }

  .interactable-btn {
    padding: 0 7px;
  }
}
