.enclosure-landing-container p,
h1,
h2,
h3 {
  color: #fff;
}

.enclosure-landing-container h2 {
  text-transform: uppercase;
  color: var(--brownish-orange);
}

.hero-two-img {
  width: 100%;
}

.about-section {
  position: relative;
  width: 100%;
  min-height: 800px;
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px;
}

.about-section img {
  width: 350px;
  height: auto;
  position: absolute;
  left: -80%;
}

.about-section-carousel {
  background-color: var(--greyish-brown);
  color: #fff;
  width: 75%;
  min-height: 500px;
  padding: 25px;
  display: flex;
  justify-content: center;
}

.about-section-carousel > div {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

#hero {
  position: relative;
}

#hero > h1 {
  font-size: 50px;
  position: absolute;
  width: 100%;
  bottom: 50px;
  z-index: 100;
  line-height: 1;
  text-align: start;
  padding: 2.5%;
}

.content-container {
  background-color: var(--black);
  color: var(--font-color-two);
}

.content-container > p {
  max-width: 950px;
  margin: 34px auto;
  margin-top: 32px;
  font-size: 26px;
  transition: all 200ms ease-in-out;
}

.hero-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);

  pointer-events: none;
  position: absolute;
}

#enclosure-interactable-section {
  padding: 50px 0;
}

.enclosure-landing-btn {
  background-color: var(--brownish-orange);
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: openSans-Bold;
  margin: 15px;
  padding: 10px 25px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  transition: all 400ms ease-in-out;
}

.enclosure-landing-btn:hover {
  background-color: var(--dark-orange);
}

.enclosure-about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 25px;
  padding-bottom: 25px;
}

.enclosure-about-section-card-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
}

.enclosure-about-card {
  color: var(--font-color-two);
  width: 30%;
  max-width: 410px;
  min-height: 350px;
  margin: 75px auto 25px auto;
}

.enclosure-about-card img {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 900px) {
  .enclosure-about-section-card-container {
    flex-wrap: wrap;
  }

  .enclosure-about-card {
    min-width: 250px;
  }
}

@media screen and (max-width: 750px) {
  .enclosure-landing-btn {
    margin: 0;
  }

  .enclosure-about-card {
    margin-top: 50px;
    min-height: 325px;
  }
}

@media screen and (max-width: 500px) {
  #hero > h1 {
    width: 100%;
  }
  .enclosure-landing-btn {
    font-size: 20px;
    padding: 12px 30px;
  }
}
