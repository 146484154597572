@media screen and (max-width: 950px) {
  .mobile-menu-dropdown {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    font-size: 16px;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-in-out;
  }

  .show-dropdown {
    height: fit-content;
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .mobile-menu-dropdown > a {
    margin: 5px;
  }

  .mobile-menu-dropdown > a:hover {
    color: var(--brownish-orange);
  }

  .dropdown-arrow {
    color: #fff;
    font-size: 20px;
    margin-left: 25px;
    border: 1px solid #fff;
    border-radius: 2px;
    height: 30px;
    width: 30px;
    transition: all 150ms ease-in-out;
  }

  .rotate {
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 900px) {
  .info-card {
    flex-direction: column !important;
  }

  .info-card-img,
  .info-card-data {
    width: 75%;
  }

  .fabrication-equipment-list-left,
  .fabrication-equipment-list-right {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .hero-section {
    min-height: 45vh;
  }

  .hero-header {
    padding: 50px;
  }

  .hero-header > h1 {
    font-size: 32px;
  }

  .hero-header > h4 {
    font-size: 18px;
  }

  .custom-manufacturing-link-container {
    flex-direction: column;
  }

  .custom-manufacturing-link-img {
    height: 400px;
    width: 90% !important;
    margin: auto !important;
  }

  .custom-manufacturing-links {
    height: fit-content;
    width: 90% !important;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .hero-section {
    min-height: 40vh;
  }

  .hero-header {
    padding: 25px;
  }

  .link-container {
    flex-direction: column;
  }

  .link-container-container {
    width: 65%;
  }

  .info-card {
    padding: 25px 0;
  }

  .info-card-img,
  .info-card-data {
    width: 95%;
  }

  .info-section {
    padding: 25px 15px;
  }

  .video-component-video {
    height: 250px;
  }

  .video-component-play-btn {
    width: 40px;
  }

  .fabrication-equipment-intro {
    padding: 25px 0 !important;
  }

  .fabrication-equipment-list-container {
    padding: 0;
  }

  .fabrication-equipment-list-left,
  .fabrication-equipment-list-right {
    min-width: 100% !important;
    padding: 0 !important;
  }

  .link-container-container {
    width: 80%;
  }

  .contact-page {
    padding: 25px 0;
  }

  .contact-container {
    width: 100%;
    padding: 15px;
  }

  .custom-manufacturing-link-img {
    height: 250px;
  }

  .custom-manufacturing-links {
    width: 100% !important;
  }
}
