.enclosure-page {
  padding: 60px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.enclosure-page > p {
  width: 100%;
  text-align: start;
  margin: 25px 0;
}

.enclosure-page-select-products {
  display: flex;
  width: 100%;
  max-width: 1350px;
  border-bottom: 2px solid var(--brownish-orange);
}

.enclosure-page-select-products li {
  color: #000;
  font-size: 20px;
  font-family: openSans-bold;
  list-style: none;
  padding: 0 3vw;
  cursor: pointer;
}

.products-other-products {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
  margin: 15px 50px;
}

.products-other-products > div > a {
  margin: 10px;
}

.other-img-container {
  display: flex;
  flex-wrap: wrap;
}

.other-img {
  width: 150px;
  height: auto;
  margin: 10px;
  box-shadow: 0 0 5px 0 #333;
  padding: 10px;
  border-radius: 5px;
}

.product-padding {
  width: 100%;
  height: 40px;
}

@media screen and (max-width: 900px) {
  .enclosure-page {
    padding-top: 20px;
  }

  .enclosure-page-select-products li {
    margin-right: 10px;
    padding: 0 1vw;
  }
}

@media screen and (max-width: 800px) {
  .enclosure-page-select-products li {
    font-size: 18px;
  }

  .product-padding {
    height: 25px;
  }
}

@media screen and (max-width: 550px) {
  .product-padding {
    height: 0;
  }
}

@media screen and (max-width: 500px) {
  .enclosure-page {
    padding: 10px 0;
    padding-bottom: 50px;
  }

  .enclosure-page-select-products {
    width: 95%;
    border-bottom: 2px solid var(--brownish-orange);
  }

  .enclosure-page-select-products li {
    margin-right: 8px;
  }
}
