.contact-container {
  background-color: #f1f1f1;
  color: #000;
  width: 85%;
  max-width: 1320px;
  min-height: 450px;
  height: fit-content;
  padding: 45px;
  margin: 45px 0;
}

.contact-container h2 {
  color: var(--brownish-orange);
  text-align: center;
}

.contact-content-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 45px 0;
}

.contact-text-container {
  text-align: start;
  max-width: 800px;
  width: 100%;
  height: fit-content;
}

.contact-text-container > p {
  font-size: 20px;
  margin: 0 45px 45px 0;
}

.contact-info {
  font-size: 26px;
  display: flex;
  align-items: center;
  margin: 15px;
  margin-top: 0;
  text-align: start;
}

.contact-info p {
  font-family: openSans-Bold;
}

.contact-icons {
  width: 25px;
  height: auto;
  margin: 10px;
  margin-right: 30px;
}

@media screen and (max-width: 900px) {
  .contact-content-container {
    padding: 0;
  }

  .contact-text-container p {
    font-size: 24px;
  }
}

@media screen and (max-width: 725px) {
  .contact-container > h2 {
    font-size: 42px;
  }

  .contact-content-container {
    flex-wrap: wrap;
  }

  .contact-text-container p {
    font-size: 20px;
    margin: 25px;
  }
}

@media screen and (max-width: 550px) {
  .contact-page {
    padding: 0;
  }

  .contact-container {
    padding: 0;
  }

  .contact-text-container p {
    margin: 15px 0;
  }
}
