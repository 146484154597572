.hero-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 515px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.hero-shader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.hero-header {
  color: #fff;
  z-index: 1;
  text-align: start;
  width: 100%;
  max-width: 1700px;
  padding: 2.5%;
  margin: 0 auto;
}

.hero-header > h1 {
  font-size: 50px;
}

.hero-header > h3 {
  color: #fff;
  font-size: 24px;
  max-width: 500px;
}

.hero-header > h4 {
  font-size: 24px;
}

.intro-section {
  padding: 75px 25px 50px 25px;
}

.intro-section > p {
  max-width: 950px;
  margin: auto;
}

.intro-section > p:first-child {
  font-size: 26px;
}

.intro-section > h3 {
  color: #000;
}

.card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1850px;
  margin: auto;
}

.card-container > h2 {
  color: var(--brownish-orange);
}

/* card */
.info-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 90%;
  margin: auto;
}

.info-card-img,
.info-card-data {
  width: 50%;
  padding: 25px;
  text-align: start;
}

.info-card-data > h3 {
  color: #000;
}

/* info section */
.info-section {
  width: 100%;
  padding: 50px;
  background-color: var(--black-three);
  color: #fff;
  margin-top: 50px;
}

/* contact component */
.contact-component {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px 25px;
}

.contact-component > h3 {
  color: #000;
  margin: 8px 0;
  font-size: 28px !important;
}

.contact-component > p {
  font-size: 22px;
}

.contact-btn {
  border: none;
  background-color: var(--brownish-orange);
  color: #fff;
  width: fit-content;
  padding: 10px 25px;
  margin: 15px;
  cursor: pointer;
  font-family: openSans-Bold;
  text-align: center;
  transition: all 400ms ease-in-out;
}

.contact-btn:hover {
  background-color: var(--dark-orange);
}

/* link component */
.link-component {
  background-color: #fff;
  padding: 75px 0;
}

.link-component > h2 {
  color: var(--brownish-orange);
}

.link-container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.link-container-container {
  width: 45%;
  margin: auto;
  padding: 15px;
  padding-top: 0;
}

.link-container-container > div > a {
  font-weight: bolder;
  padding: 15px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}

.link-container-container > div > a > h3 {
  width: 100%;
  text-align: start;
  color: #000;
}

.link-container-container > div > a > h3:hover {
  color: var(--brownish-orange);
}

.link-component-arrow {
  color: var(--brownish-orange);
  font-size: 24px;
}

.video-component {
  padding-bottom: 50px;
}

.video-component-thumbnail-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video-component > h2 {
  color: var(--brownish-orange);
  margin: 25px;
}

.video-component > p {
  margin: 25px auto;
  padding-bottom: 25px;
  max-width: 700px;
}

.video-component-video {
  width: 100%;
  max-width: 800px;
  height: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  position: relative;
}

.video-component-play-btn {
  cursor: pointer;
  width: 50px;
  box-shadow: 0 0 1px 1px var(--warm-grey);
  border-radius: 100%;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .contact-component {
    padding: 70px 25px;
  }
}
