.contact-body {
  background: #f0f0f0;
  display: flex;
  padding: 60px 10%;
}
.contact-body-wrapper {
  background: #f0f0f0;
}
.contact-form {
  width: 80%;
  margin-left: auto;
}
.contact-form-title {
  color: #19444b;
  font-size: 36px;
}
.contact-form-errors {
  color: #de592e;
  margin-bottom: 16px;
}
.contact-form-button {
  border: 1px solid #aaa;
  color: #aaa;
  font-size: 18px;
  margin-top: 16px;
  padding: 12px 0;
  width: 25%;
  font-weight: bolder;
}
.contact-form-button:focus {
  outline: none;
}
.contact-form-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.contact-form form input {
  background-color: #fff !important;
  border: 1px solid #fff;
  color: #6d6d6d;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px 12px;
  width: 100%;
}
.contact-form form input:focus {
  border-bottom: 1px solid #de592e;
  outline: 0;
}
.contact-form form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  appearance: none;
  background-color: #fff !important;
  color: #6d6d6d !important;
}

.contact-form form textarea {
  background-color: #fff !important;
  border: 1px solid #fff;
  color: #6d6d6d;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px 12px;
  width: 100%;
}
.contact-form form textarea:focus {
  border-bottom: 1px solid #de592e;
  outline: 0;
}
.contact-form form textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  appearance: none;
  background-color: #fff !important;
  color: #6d6d6d !important;
}
.contact-thanks {
  color: #6d6d6d;
  font-size: 18px;
  margin-top: 42px;
}
.contact-thanks-you {
  font-size: 24px;
}

@media screen and (max-width: 1100px) {
  .contact-body {
    padding: 60px 5%;
  }
  .contact-form {
    margin-right: 5%;
  }
}
@media screen and (max-width: 800px) {
  .contact-body {
    display: block;
    padding: 40px 5%;
  }
  .contact-form {
    width: 100%;
  }
  .contact-form-title {
    font-size: 32px;
  }
  .contact-form form {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .contact-form-button {
    padding: 12px 0;
    width: 35%;
  }
  .contact-info {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .contact-form-title {
    font-size: 28px;
  }
  .contact-form-button {
    width: 50%;
  }
}
@media screen and (min-width: 1442px) {
  .contact-body {
    margin: 0 auto;
    max-width: 1442px;
  }
}
