#collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.collection-card-container a {
  color: var(--font-color);
}

#collection > h2 {
  text-transform: uppercase;
  margin: 50px;
}

/* this is the video section */
.video-section {
  width: 100%;
  background-color: var(--black-three);
  padding: 40px 0;
  padding-bottom: 80px;
}
.video-section-container {
  background-color: var(--black-three);
  display: flex;
  justify-content: center;
  padding: 40px 0;
  margin: 0 25px;
}

.video-section-large {
  width: 50%;
  max-width: 700px;
  cursor: pointer;
}

.video-section-large > h3 {
  font-size: 18px;
  text-align: left;
  color: #fff;
}

.video-section-small {
  width: 50%;
  max-width: 700px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.small-video-container {
  width: 43%;
  height: 250px;
  text-align: start;
  margin: 15px;
  margin-right: 0;
  margin-top: 0;
  cursor: pointer;
}

.small-video-container h3 {
  font-size: 18px;
  color: #fff;
}

.video-section-thumbnail-container {
  position: relative;
}

.video-section-thumbnail-container:first-child {
  width: 100%;
}

/* video modal */
.video-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.video-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-modal-header > h3 {
  margin-left: 25px;
}

.video-close-btn {
  color: var(--warm-grey);
  font-size: 32px;
  margin: 15px;
  margin-right: 75px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.video-close-btn:hover {
  background-color: var(--black-two);
  border-radius: 2px;
}

.video-modal-iframe-container {
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.video-modal-btn {
  background-color: var(--black-two);
  color: var(--warm-grey-two);
  font-size: 48px;
  border: none;
  min-height: 250px;
  margin: 15px;
  padding: 0 5px;
  cursor: pointer;
}

.disabled {
  color: var(--black);
}

/* this is the image card section */
.collection-container {
  width: 100%;
  max-width: 1350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.collection-container > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 65vh;
}

.collection-card-container {
  width: 18.4%;
  margin: 30px 0.5vw;
}

.collection-card-container > a {
  width: 100%;
}

.collection-card-img-container {
  width: 100%;
  height: 200px;
  cursor: pointer;
  border: 1px solid rgba(189, 195, 199, 0.7);
  transition: all 200ms ease-in-out;
}

.collection-card-img-container > a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-card-content-container {
  width: 100%;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--greyish-brown);
  transition: all 200ms ease-in-out;
}

.collection-card-content-container p {
  color: #fff;
  width: 95%;
  text-align: start;
  margin: 5px;
}

.collection-card-content-container h4 {
  font-size: 20px;
  font-family: openSans-Bold;
  margin: 0 5px;
  width: 95%;
  text-align: start;
  color: #fff;
  line-height: 24px;
}

.collection-card-content-container p {
  font-size: 17px;
}

.collection-card-img {
  width: 80%;
  max-height: 100%;
}

.download-icon-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.play-icon-container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.play-btn {
  width: 12%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orange-background {
  background-color: var(--brownish-orange);
  transition: all 200ms ease-in-out;
}

.orange-border {
  border: 1px solid var(--brownish-orange);
  transition: all 200ms ease-in-out;
}

.max-width-100 {
  max-width: 100%;
}

@media screen and (max-width: 1280px) {
  .video-section-container {
    flex-direction: column;
    align-items: center;
  }

  .video-section-large {
    width: 80%;
    max-width: 80%;
    margin-bottom: 15px;
  }
  .video-section-small {
    width: 80%;
    max-width: 80%;
  }

  .small-video-container {
    margin: auto;
    height: auto;
  }
}

@media screen and (max-width: 970px) {
  .collection-card-container {
    width: 30.5%;
    min-width: unset;
    margin: 20px 8px;
  }

  .collection-card-img {
    width: 80%;
    height: auto;
  }

  .video-modal-iframe-container {
    width: 100%;
    height: auto;
  }

  .video-modal-iframe {
    width: 100%;
    height: 400px;
  }

  .video-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
  }

  .video-modal-btn {
    position: absolute;
    margin: 15px;
    bottom: 0;
    min-height: unset;
    height: 65px;
    width: 65px;
    min-width: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-btn {
    left: 0;
  }

  .right-btn {
    right: 0;
  }
}

@media screen and (max-width: 750px) {
  .video-section-large {
    width: 90%;
  }
  .small-video-container {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .collection-card-container {
    width: 46%;
  }
  .video-section-container {
    margin: 0;
  }
}

@media screen and (max-width: 550px) {
  #collection > h2 {
    font-size: 42px;
    margin: 25px 0;
    padding: 25px;
  }

  .collection-container {
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-top: 50px;
  }

  .enclosure-page > p {
    padding: 0 15px;
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }

  .collection-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .collection-card-container {
    width: 46%;
    margin: 20px 9px;
  }
}

@media screen and (max-width: 465px) {
  .collection-container > div {
    justify-content: center;
  }
  .collection-card-container {
    width: 250px;
  }
}
@media screen and (max-width: 380px) {
  .collection-img-container {
    width: 100%;
    height: 100%;
  }

  .collection-container > div {
    align-items: center;
    justify-content: center;
  }

  .collection-card-container {
    width: 70%;
  }
  .video-section-large h3 {
    text-align: start;
  }
  #collection > h2 {
    margin-bottom: 0;
  }
}
