.header-container {
  top: 0;
  width: 100%;
  z-index: 100;
}

.header-content {
  min-height: 50px;
  background-color: #1a2127;
  color: var(--font-color);
  padding: 10px;
  display: flex;
  align-items: center;
}
.header-max-width {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  max-width: 1600px;
  margin: auto;
  padding-right: 15px;
}

.header-content h1 {
  color: #fff;
}

.header-logo-container {
  display: flex;
  align-items: center;
}

.header-logo-container img {
  width: 120px;
  margin-right: 16px;
}

.header-social-icon-container img {
  height: 24px;
  margin-left: 16px;
}

.header-btn-round {
  outline: none;
  border: none;
  border-radius: 50px;
  padding: 5px 25px;
  margin-right: 15px;
  color: #fff;
  background-color: var(--brownish-orange);
  font-weight: bolder;
  font-size: 18px;
  cursor: pointer;
}

.amp-logo-header {
  height: 45px;
  width: auto;
  margin: 13px 0;
  margin-left: 2px;
}

.nav {
  background-color: var(--header-black);
  min-height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 0 5px #000;
  position: relative;
  padding: 0 8px;
}

.menu-items {
  display: flex;
  height: 76px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.menu-items a {
  height: 100%;
  margin: 0 15px;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: openSans-Bold;
}

.menu-items a > div {
  font-family: openSans-Bold;
}

.menu-items a > div:hover {
  color: var(--brownish-orange);
}

/* dropdown section */
.dropdown-selector {
  position: relative;
  height: 100%;
}

.dropdown-selector:first-child a:hover {
  color: var(--brownish-orange);
}

.menu-dropdown {
  position: absolute;
  top: 76px;
  left: -5px;
  background-color: #fff;
  z-index: 100;
  flex-direction: column;
  text-align: start;
  padding: 25px;
  width: 250px;
  box-shadow: 0 0 1px #333;
}

.menu-dropdown > a {
  color: #000;
  font-weight: bolder;
  padding: 7px;
  margin-right: 0;
  margin-left: 10px;
}

.menu-dropdown a:hover {
  color: var(--brownish-orange);
}

/* this is the mobile menu section */
.mobile-menu-btn {
  display: none;
  color: #fff;
}

.mobile-menu {
  background-color: var(--greyish-brown);
  display: none;
  text-align: start;
  transition: all 400ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0;
  width: 100%;
  padding: 25px;
  position: absolute;
  z-index: 100;
  top: 65px;
  box-shadow: 0 2px 2px -2px #000;
}

.mobile-menu-head > ul > li {
  user-select: none;
  list-style: none;
  padding: 15px;
}

.mobile-menu a {
  color: var(--font-color-two);
}

.mobile-menu-head > ul > li > a:hover {
  color: var(--brownish-orange);
}

.mobile-menu-hide {
  display: none;
  pointer-events: none;
  position: fixed;
}

@media screen and (max-width: 950px) {
  .header-menu > h2 {
    font-size: 28px;
  }

  .header-social-icon-container {
    display: none;
  }

  .amp-logo-header {
    height: 35px;
  }

  .header-max-width {
    width: 95%;
  }

  .menu-items {
    display: none;
  }

  .mobile-menu-btn {
    display: unset;
    font-size: 36px;
    margin: 0 20px;
    cursor: pointer;
  }

  .mobile-menu-show {
    height: fit-content;
    border-top: 3px solid var(--warm-grey);
    z-index: 1000;
  }

  .mobile-menu-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  .amp-logo-header {
    margin: 15px 0;
  }
}

@media screen and (max-width: 750px) {
  .mobile-menu-btn {
    margin: 0;
  }

  .header-menu {
    padding: 0 20px;
    min-height: 70px;
  }

  .header-content {
    padding: 5px;
  }

  .header-social-icon-container img {
    margin-left: 8px;
  }

  .header-logo-container img {
    width: 100px;
    margin-right: 8px;
    margin-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .header-max-width {
    padding-right: 0 !important;
  }

  .header-logo-container {
    width: 100%;
    justify-content: space-between;
  }

  .nav {
    padding: 0;
  }
}
