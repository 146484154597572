.single-product-page {
  min-height: 100vh;
  text-align: left;
  padding-top: 25px;
  position: relative;
}

.breadcrumb-container {
  max-width: 1260px;
  margin: auto;
  padding: 0 25px;
}

.single-product-page h3 {
  font-size: 20px;
  margin: 10px 0;
  color: var(--font-color);
}

.single-product-page h1,
.single-product-page h2 {
  color: var(--font-color);
  font-family: openSans-Bold;
}

.breadcrumb-container ul {
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 1600px;
}

.breadcrumb-container li {
  list-style: none;
  margin: 10px;
  font-size: 14px;
  font-family: "openSans-Bold";
  font-weight: 500;
  color: var(--brownish-grey);
}

.single-product-main-content {
  display: flex;
  justify-content: center;
  max-width: 1250px;
  width: 100%;
  margin: auto;
  padding: 0 25px;
}

.single-product-img-container {
  margin: 20px 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 20px;
  height: fit-content;
}

.main-img {
  width: 350px;
  height: auto;
  max-height: 100%;
  transition: all 300ms ease-in-out;
}

.single-product-main-img {
  background-color: var(--light-grey);
  color: var(--font-color-two);
  width: 435px;
  height: 435px;
  max-height: 100%;
  margin: 15px;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-product-other-imgs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}

.single-product-other-imgs > div {
  background-color: var(--light-grey);
  width: 70px;
  height: 70px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-product-other-imgs > div > img {
  width: 80%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.single-product-data-container {
  width: 60%;
  margin-top: 75px;
}

.single-product-data-container > div {
  margin: 45px 0;
}

.single-product-data-container > div > h2 {
  margin: 10px 0;
  font-size: 26px;
  font-family: openSans-regular;
  font-weight: 300;
}

.single-product-resources {
  display: flex;
  flex-direction: column;
  margin: 25px 15px;
}

.single-product-resources > div {
  display: flex;
  flex-direction: column;
}

/* .single-product-data-container li {
  list-style: none;
  margin: 5px;
  margin-left: 20px;
  position: relative;
} */

/* .single-product-data-container li::before {
  margin-right: 0.5em;
  width: 0.25em;
  height: 0.25em;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
  left: -15px;
  top: 10px;
  content: " ";
} */
/* 
.single-enclosure-includes-section > ul > li {
  margin-left: 35px;
} */

.use-case-warranty-container {
  display: flex;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

.use-case-container {
  width: 65%;
  margin: 10px;
}

.warranty-container {
  width: 35%;
  margin: 10px;
}

.kit-includes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background-color: var(--background-gray);
}

.kit-includes-container > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.kit-includes-img-container {
  width: 300px;
  margin: 25px 75px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.kit-includes-img-container > img {
  margin: auto;
  width: 75%;
}

.kit-includes-img-container > h3 {
  margin: 25px 0;
}

@media screen and (max-width: 1220px) {
  .single-product-img-container {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .single-product-page {
    padding-top: 0;
  }

  .single-product-main-img {
    margin: 15px auto;
  }

  .single-product-main-content {
    flex-direction: column;
    align-items: center;
  }

  .single-product-data-container {
    width: 90%;
    margin-top: 0;
  }

  .single-product-img-container {
    width: 100%;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .breadcrumb-container {
    width: 100% !important;
  }

  .single-product-img-container > div {
    width: fit-content;
  }

  .use-case-warranty-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .use-case-warranty-container div {
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .breadcrumb-container ul {
    padding-left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .breadcrumb-container ul {
    margin-top: 25px;
  }
}

@media screen and (max-width: 800px) {
  .padding-50 {
    padding: 0 !important;
  }

  .breadcrumb-container ul {
    padding-left: 0;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  .series-page {
    padding: 10px !important;
  }

  .series-enclosure-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .single-product-img-container {
    width: 100%;
  }

  .breadcrumb-container > ul > a {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 480px) {
  .single-product-page {
    padding: 10px;
    padding-bottom: 50px;
  }

  .main-img {
    width: 250px;
  }

  .single-product-img-container {
    margin: 10px 0 0 0;
  }

  .single-product-main-img {
    width: 300px;
    height: 300px;
  }

  .breadcrumb-container li {
    margin: 5px;
  }

  .single-product-img-container > div {
    width: 100%;
  }
}
