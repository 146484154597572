.fabrication-page h3,
.fabrication-page h4,
.fabrication-page h5 {
  color: #000;
}

.fabrication-hero {
  background-image: url("../imgs/hero-imgs/Amp\ Fabrication.jpg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 515px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.fabrication-hero-shader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.fabrication-hero > h1 {
  font-size: 50px;
  text-align: start;
  width: 80%;
  max-width: 900px;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 10vh;
  z-index: 1;
}

.fabrication-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 75px 50px;
  width: 80%;
  max-width: 1300px;
  margin: auto;
}

.fabrication-intro > h2 {
  color: var(--brownish-orange);
  font-size: 34px;
}

.fabrication-about {
  display: flex;
}

.fabrication-about-left-img {
  background-image: url("../imgs/mfg/5.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
}

.fabrication-about-right-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--background-blue);
  color: #fff;
  text-align: start;
  padding: 50px;
  width: 50%;
}

.fabrication-about-right-info > h3 {
  color: #fff;
  margin: 0;
}

.fabrication-about-right-info > p {
  color: #fff;
}

.fabrication-about-right-info li {
  list-style: inside;
}

.fabrication-collab-intro {
  padding: 75px 50px;
  margin: auto;
  width: 70%;
  max-width: 1300px;
}

.fabrication-collab-intro > h3 {
  margin: 0;
  margin-bottom: 15px;
}

.fabrication-collab-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.fabrication-collab-card {
  background-color: var(--background-blue);
  color: #fff;
  width: 315px;
  height: 220px;
  margin: 10px;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fabrication-collab-card > h3 {
  line-height: 1;
  margin: 0;
  font-size: 20px;
  color: #fff;
}

.fabrication-collab-card > p {
  color: #fff;
  font-size: 15px;
}

.fabrication-equipment > hr {
  max-width: 1300px;
  margin: 15px auto;
}

.fabrication-about {
  height: 40vh;
  min-height: 450px;
  max-width: 1400px;
  margin: auto;
}

.fabrication-equipment-intro {
  width: 80%;
  padding: 25px;
  max-width: 1300px;
  margin: auto;
}

.fabrication-equipment-intro > h2 {
  margin: 8px;
  color: var(--brownish-orange);
}

.fabrication-equipment-list-container {
  display: flex;
  flex-wrap: wrap;
  text-align: start;
  padding: 25px;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.fabrication-equipment-list-container h3 {
  font-size: 20px;
  margin: 0;
}

.fabrication-equipment-list-container li {
  list-style: inside;
}

.fabrication-equipment-list-left > div,
.fabrication-equipment-list-right > div {
  margin-bottom: 15px;
  padding: 10px;
}

.fabrication-equipment-list-left,
.fabrication-equipment-list-right {
  width: 50%;
  min-width: 400px;
  margin: 0 auto;
  padding: 0 30px;
}

.custom-manufacturing-link-container {
  display: flex;
  justify-content: center;
}

.custom-manufacturing-link-container > div {
  width: 45%;
}

.custom-manufacturing-link-img {
  background-position: center;
  background-size: cover;
  margin: 25px;
  transition: all 200ms ease-in-out;
}

@media screen and (max-width: 950px) {
  .fabrication-hero > h1 {
    font-size: 34px;
  }
}

@media screen and (max-width: 900px) {
  .fabrication-about {
    display: flex;
    flex-direction: column;
    height: 75vh;
    min-height: 750px;
  }

  .fabrication-about-left-img,
  .fabrication-about-right-info {
    height: 50%;
    width: 80%;
    margin: auto;
  }

  .fabrication-intro > h2 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 850px) {
  .fabrication-equipment-list-left,
  .fabrication-equipment-list-right {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .fabrication-hero {
    min-height: 50vh;
  }
}

@media screen and (max-width: 650px) {
  .fabrication-hero {
    min-height: 40vh;
  }

  .fabrication-hero > h1 {
    font-size: 30px;
    margin-bottom: 6vh;
  }

  .fabrication-intro {
    padding: 50px 0;
  }

  .fabrication-collab-intro {
    padding: 75px 0;
  }

  .fabrication-equipment-intro {
    padding: 50px 0;
  }
}

@media screen and (max-width: 550px) {
  .fabrication-about {
    min-height: 800px;
  }

  .fabrication-about-left-img,
  .fabrication-about-right-info {
    width: 100%;
  }

  .fabrication-about-right-info li {
    font-size: 16px;
    margin: 5px 0;
  }

  .fabrication-about-right-info ul {
    margin: 10px 0;
  }

  .custom-manufacturing-links {
    margin-top: 15px;
  }
}

@media screen and (max-width: 480px) {
  .fabrication-hero {
    background-size: cover;
  }
}
