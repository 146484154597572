.thermoforming-table-container {
  background-color: var(--background-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;
  margin-top: 50px;
}

.thermoforming-table-container > div > h2 {
  color: var(--brownish-orange);
}

.thermoforming-table-container h3 {
  color: #000;
}

.thermoforming-table-container table {
  margin: 50px auto;
  border-spacing: 7px;
  max-width: 1000px;
}

.text-orange {
  color: var(--brownish-orange) !important;
}

.background-orange {
  background-color: var(--brownish-orange);
  color: #fff;
  font-family: openSans-Bold;
}

.background-black {
  background-color: #000;
  color: #fff;
  font-family: openSans-Bold;
}

.background-white {
  background-color: #fff;
  color: #000;
  font-family: openSans-Bold;
}

.td-padding {
  padding: 15px 50px;
}

.td-padding-small {
  padding: 15px 15px 15px 0;
}

.max-width-500 {
  width: 500px;
}

.max-width-900 {
  max-width: 900px;
}

.td-height {
  height: 74px;
}

@media screen and (max-width: 900px) {
  .thermoforming-table-container table {
    margin: 25px;
  }

  .td-padding {
    padding: 15px 20px;
  }
}

@media screen and (max-width: 780px) {
  .thermoforming-table-container {
    padding: 25px 50px;
  }

  .thermoforming-table-container table {
    margin: 25px 0;
  }

  .td-padding {
    padding: 10px;
  }
}

@media screen and (max-width: 680px) {
  .thermoforming-table-container {
    padding: 50px 15px;
  }

  .background-white {
    font-size: 14px;
  }
}

@media screen and (max-width: 580px) {
  .td-padding {
    padding: 5px;
  }

  .td-height {
    height: fit-content;
  }

  .thermoforming-table-container table > tbody > tr > td > h4 {
    font-size: 12px;
  }

  .thermoforming-table-container {
    padding: 50px 10px;
  }
}
