.App {
  background-color: #fff;
  color: var(--font-color);
  text-align: center;
}

p {
  font-family: openSans-regular;
}

h1,
h2,
h4 {
  font-family: openSans-Extra-Bold;
}

h3 {
  font-family: openSans-Bold;
}

@media screen and (max-width: 900px) {
  .content-container > p {
    font-size: 18px;
    padding: 0 25px;
  }

  #hero > h1 {
    font-size: 34px;
  }
}

@media screen and (max-width: 950px) {
  h2 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 800px) {
  p {
    font-size: 16px !important;
  }

  h3 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 32px !important;
  }

  h2 {
    font-size: 26px !important;
  }
}
