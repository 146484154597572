.about-container {
  background-color: var(--black-two);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 125px 0 50px 0;
}

.about-container > h2 {
  color: var(--brownish-orange);
  font-size: 48px;
  margin-bottom: 40px;
}

.about-container > p {
  font-size: 28px;
  max-width: 1000px;
}

.about-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.about-card-container {
  width: 43%;
  margin: 25px;
}

.about-card {
  background-color: #fff;
  color: #000;
  width: 97%;
  min-height: 340px;
  padding: 45px 45px;
  margin: 25px;
  text-align: start;
  border-bottom: 10px solid var(--light-grey);
}

.about-card img {
  width: 60px;
  margin: 15px 0 25px 0;
}

.about-card > h3 {
  letter-spacing: 2.57px;
  font-size: 28px;
}

.about-card > p {
  color: var(--black);
  font-size: 14px;
  line-height: 25px;
  margin: 15px 0;
}

.bitmap-img {
  max-width: 45%;
  max-height: 710px;
  margin: 0 20px;
}

@media screen and (max-width: 950px) {
  .about-container {
    padding-top: 100px;
  }

  .about-content {
    flex-direction: column;
  }

  .bitmap-img {
    max-width: 85%;
    height: auto;
  }

  .about-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-card {
    width: 85%;
    padding: 35px 20px !important;
    margin: 10px;
  }

  .about-container h2 {
    font-size: 42px;
  }

  .about-container > p {
    font-size: 18px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .about-container {
    padding-top: 50px;
  }

  .about-content {
    margin-top: 25px;
  }

  .about-container > p {
    padding: 0 30px;
    text-align: start;
  }

  .about-card img {
    margin: 15px;
  }
}
