@font-face {
  font-family: "openSans-regular";
  src: local("openSans-regular"),
    url("./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "openSans-semiBold";
  src: local("openSans-semibold"),
    url("./assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "openSans-Bold";
  src: local("openSans-bold"),
    url("./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "openSans-Extra-Bold";
  src: local("openSans-Extra-bold"),
    url("./assets/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf");
}

* {
  font-family: "openSans-regular";
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

h2,
h3 {
  margin: 15px 0;
  font-family: openSans-Bold;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

p {
  font-size: 18px;
}

li {
  font-size: 20px;
}

/* variables */
:root {
  --black: #1c1a1a;
  --black-two: #2a2a2a;
  --black-three: #262525;
  --black-four: #222;
  --greyish-brown: #3c3c3c;
  --brownish-grey: #515050;
  --warm-grey: #848484;
  --warm-grey-two: #7e7e7e;
  --brownish-orange: #ff5722;
  --dark-orange: #b94420;
  --light-orange: #ee8a34;
  --light-grey: #f6f6f6;
  --light-blue: #5cbde8;
  --blue: #3488ee;
  --header-black: #353535;

  --font-color: #000;
  --font-color-two: #fff;

  --ampthink-blue-grey: #1a2127;
  --background-blue: #22282f;
  --background-white: #eaeaea;
  --background-gray: #e9e9e9;

  /* font sizes */
  --x-large-font: 58px;
  --large-font: 42px;
  --medium-font: 32px;
  --regular-font: 26px;
  --label-font: 18px;
  --small-font: 16px;
}

/* animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes pulse1 {
  0% {
    width: 30%;
    height: 30%;
  }
  100% {
    width: 140%;
    height: 140%;
    opacity: 0;
  }
}

@keyframes pulse2 {
  0% {
    width: 70%;
    height: 70%;
  }
  100% {
    width: 150%;
    height: 150%;
    opacity: 0;
  }
}

@keyframes point {
  0% {
    transform: translateX(-25%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
}

@keyframes rotatePoint {
  0% {
    transform: translateX(-25%) rotate(180deg);
  }
  50% {
    transform: translateX(0) rotate(180deg);
  }
  100% {
    transform: translateX(-25%) rotate(180deg);
  }
}

@media screen and (max-width: 1080px) {
  :root {
    --x-large-font: 58px;
    --large-font: 42px;
    --medium-font: 32px;
    --regular-font: 26px;
    --label-font: 18px;
    --small-font: 16px;
  }
}

@media screen and (max-width: 992px) {
  :root {
    --x-large-font: 50px;
    --large-font: 38px;
    --medium-font: 28px;
    --regular-font: 24px;
    --label-font: 18px;
    --small-font: 16px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --x-large-font: 46px;
    --large-font: 32px;
    --medium-font: 24px;
    --regular-font: 20px;
    --label-font: 16px;
    --small-font: 14px;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --x-large-font: 40px;
    --large-font: 28px;
    --medium-font: 20px;
    --regular-font: 18px;
    --label-font: 16px;
    --small-font: 14px;
  }
}

@media screen and (max-width: 800px) {
  li {
    font-size: 16px;
  }
}
