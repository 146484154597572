.series-page {
  min-height: 100vh;
  text-align: left;
  padding: 25px 0 0 0;
  position: relative;
}

.series-page p {
  line-height: 1.7;
  margin: 15px 0;
}

.margin-auto {
  margin: auto;
}

.tech-specs-list-container > ul,
.tech-specs-list-container > ul > li {
  list-style: none;
  padding: 5px 0;
}

.tech-specs-list-container > ul > li > hr {
  margin-top: 5px;
}

.series-enclosure-btn-container {
  margin: 20px 0;
}

.series-enclosure-select-btn {
  padding: 12px 52px;
  margin: 5px 10px 5px 0;
  border-radius: 0;
  border: 1px solid #333;
  cursor: pointer;
}

.series-enclosure-showcase {
  display: flex;
  justify-content: center;
}

.enclosure-showcase-component-container {
  width: 350px;
  margin: 10px;
}

.enclosure-showcase-preview {
  width: 75%;
  height: auto;
}

.showcase-data-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.showcase-data-container li {
  font-size: 16px;
}

.series-showcase-label {
  width: 100%;
  text-align: center;
  font-size: 26px;
}

.showcase-section {
  background-color: var(--background-gray);
  width: 100%;
  padding: 100px 0;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.padding-25 {
  padding: 25px;
}

.padding-50 {
  padding: 50px;
}

.padding-50-0 {
  padding: 50px 0;
}

.series-page-hr {
  width: 95%;
  max-width: 1200px;
  margin: auto;
}

.series-page-header {
  max-width: 1250px;
  margin: auto;
}

.series-page-header p {
  font-size: 24px;
}

.series-page-header h2 {
  margin-top: 0;
}

.series-page-ul-dots > li {
  list-style: initial !important;
  margin-left: 20px;
  line-height: 1.7;
}

.enclosure-showcase-divider {
  margin: auto 5px;
  border: none;
  border-left: 1px solid var(--warm-grey);
  height: 400px;
}

.mobile-showcase-slider {
  display: none;
}

@media screen and (max-width: 800px) {
  .series-page-header {
    flex-direction: column-reverse;
  }
  #showcase-swiper {
    width: 100% !important;
  }

  .mobile-showcase-slider {
    display: block;
  }

  .series-page-header p {
    font-size: 20px !important;
  }

  .series-page-header > div {
    width: 100% !important;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-hr-mobile {
    opacity: 0;
  }

  .showcase-section {
    padding: 50px 0;
  }
}
