#carousel-two {
  margin-bottom: 40px;
}

.carousel-two-swiper {
  width: 100%;
  max-width: 100%;
  margin-bottom: 100px;
}

.carousel-two-slide {
  width: 100%;
  margin: 0;
}

.carousel-two-slide div {
  width: 100vw;
  min-height: 515px;
  margin-bottom: 50px;
}

.carousel-two-slide.swiper-slide {
  width: 100% !important;
  margin-bottom: 0;
}

.carousel-two-swiper > .swiper-button-next {
  background-color: var(--black-two);
  color: #7e7e7e;
  height: 70px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  opacity: 75%;
  justify-content: center;
  transform: translate(-60%, -50%);
}

.carousel-two-swiper > .swiper-button-prev {
  background-color: var(--black-two);
  color: #7e7e7e;
  height: 75px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 75%;
  transform: translate(60%, -50%);
}

.carousel-two-swiper > .swiper-pagination {
  width: 50vw !important;
  height: 6px;
  background-color: transparent;
  bottom: 0 !important;
  display: flex;
  justify-content: space-around;
  transform: translate(50%);
}

.carousel-two-swiper > .swiper-pagination > .swiper-pagination-bullet {
  flex-grow: 0.25;
  border-radius: 0;
  transition: all 200ms ease-in-out;
  background-color: var(--warm-grey);
}

.carousel-two-swiper > .swiper-pagination > .swiper-pagination-bullet-active {
  background: var(--warm-grey);
  flex-grow: 0.25;
  border-radius: 0;
}

@media screen and (max-width: 900px) {
  .carousel-two-slide div {
    min-height: 50vh;
  }

  .carousel-two-swiper > .swiper-pagination {
    transform: translate(50%);
  }

  .carousel-two-swiper > .swiper-button-next,
  .carousel-two-swiper > .swiper-button-prev {
    position: absolute;
    top: unset;
    bottom: 35%;
    opacity: 0.6;
  }

  .carousel-two-swiper > .swiper-button-next {
    right: -40px;
  }

  .carousel-two-swiper > .swiper-button-prev {
    left: -40px;
  }
}

@media screen and (max-width: 500px) {
  .carousel-two-swiper > .swiper-button-next,
  .carousel-two-swiper > .swiper-button-prev {
    width: 50px;
  }

  .carousel-two-swiper > .swiper-button-next,
  .carousel-two-swiper > .swiper-button-prev {
    display: none;
  }
}
