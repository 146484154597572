.footer-container {
  width: 100%;
  max-width: 100%;
  position: relative;
  background-color: var(--black-three);
}

.footer-content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  max-width: 1600px;
  margin: auto;
}

.ampthink-footer-logo {
  height: 45px;
  width: auto;
}

.social-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon-container img {
  width: 45px;
  height: auto;
  margin: 25px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.social-icon-container > a > img:hover {
  transform: scale(110%);
}

.footer-copyright-container {
  background-color: var(--greyish-brown);
  padding: 20px;
  text-align: center;
}

.footer-copyright-container > p {
  font-size: 12px;
  color: #fff;
}

.up-arrow {
  color: #fff;
  font-size: 32px;
  position: absolute;
  top: 25px;
  left: 50%;
  animation: bounce 2.5s ease-in-out infinite;
}

@media screen and (max-width: 900px) {
  .footer-content-container {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 50px 0 25px 0;
  }

  .ampthink-footer-logo {
    margin-top: 15px;
    margin: 0;
  }

  .social-icon-container {
    margin: 0;
  }

  .footer-copyright-container p {
    font-size: 12px !important;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .ampthink-footer-logo {
    height: 35px;
    margin: 10px;
  }

  .social-icon-container img {
    margin: 10px;
    width: 35px;
    height: auto;
  }

  .up-arrow {
    top: 15px;
    left: 46%;
  }

  .footer-copyright-container p {
    font-size: 12px !important;
    text-align: center;
  }
}
