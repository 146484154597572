.swiper {
  width: 100%;
  margin: 0;
}

.carousel-one-swiper {
  width: 100%;
}

#swiper-one-slide {
  height: 450px;
}

.swiper-slide {
  width: 450px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-one-slide img {
  width: 325px;
  height: auto;
}

@media screen and (max-width: 900px) {
  .swiper-slide {
    width: unset !important;
    height: unset !important;
  }
  .carousel-one-slide img {
    width: 325px;
    margin: 50px;
  }
}

@media screen and (max-width: 400px) {
  .carousel-one-slide img {
    width: 250px;
    margin: 30px;
    margin-top: 50px;
  }
}
