.contact-page {
  min-height: 70vh;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
}

/* .contact-form {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
  max-width: 800px;
} */

/* .contact-form > input {
  margin: 15px;
  width: 100%;
  min-width: 500px;
  padding: 10px;
} */

/* .contact-form > textarea {
  width: 100%;
  min-width: 500px;
  min-height: 300px;
  margin: 15px;
  padding: 10px;
} */

.contact-submit-btn {
  width: fit-content;
  margin: auto;
  padding: 10px 25px;
}
